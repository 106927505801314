export class TicketInformation {
  id: number;

  /**
   * ticketId
   */
  ticketId: number;

  /**
   * ticketId
   */
  ticketName: Language;

  /**
   * note
   */
  note: Language;
  /**
   * id Spot
   */
  idSpot: string;

  /**
   * isShowTicketName
   */
  isShowTicketName: boolean;

  /**
   * isShowNote
   */
  isShowNote: boolean;

  /**
   * languageSelected
   */
  languageSelected: string;

  constructor(id?: number, ticketId?: number, ticketName?: Language, note?: Language, languageSelected?: string) {
    this.id = id;
    this.ticketId = ticketId;
    this.ticketName = ticketName;
    this.note = note;
    this.languageSelected = languageSelected;
  }
}

interface Language {
  ja: string;
  en: string;
}
