export class SpotDetails {
  /**
   * spot details id
   */
  id: number;

  /**
   * spotName
   */
  spotName: Language;

  /**
   * salesCompanyName
   */
  sellerBusId: number;

  /**
   * appId
   */
  appId: string;

  /**
   *   code
   */
  code: string;

  /**
   *   QR code
   */
  spotQrCode: string;

  /**
   *   spotImage
   */
  spotImage: string;

  /**
   *   spotImageURL
   */
  spotImageURL: string;

  /**
   *   spot Image Circle
   */
  spotImageCircle: string;

  /**
   *   spot Image Circle URL
   */
  spotImageCircleURL: string;

  imagePath: string;
  imageCirclePath: string;

  /**
   *   spotImageURL
   */
  isShowSpotName: boolean;

  /**
   * id Spot
   */
  idSpot: string;

  qrBase64: any;
  constructor(
    id?: number,
    spotName?: Language,
    sellerBusId?: number,
    appId?: string,
    code?: string,
    spotQrCode?: string,
    spotImage?: string,
    spotImageURL?: string,
    spotImageCircle?: string,
    spotImageCircleURL?: string,
    idSpot?: string
  ) {
    this.id = id;
    this.spotName = spotName;
    this.sellerBusId = sellerBusId;
    this.appId = appId;
    this.code = code;
    this.spotQrCode = spotQrCode;
    this.spotImage = spotImage;
    this.spotImageURL = spotImageURL;
    this.spotImageCircle = spotImageCircle;
    this.spotImageCircleURL = spotImageCircleURL;
    this.idSpot = idSpot;
  }
}

// Interface representing language structure
interface Language {
  ja: string;
  en: string;
}
