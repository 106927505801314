import { SpotDetails } from './spot-details';
import { TicketInformation } from './ticket-information';

export class Spot {
  /**
   * spot id
   */
  id: number;

  /**
   * spot id code
   */
  spotId: string;

  /**
   * spot name
   */
  spotName: Language;
  /**
   * true if timetable is checked
   */
  isChecked: boolean;
  /**
   * true if timetable is edited
   */
  isEdit: boolean;

  /**
   * spotDetails
   */
  spotDetails: SpotDetails;

  /**
   * ticketInformation
   */
  ticketInformation: Array<TicketInformation>;

  appId: string;

  appName: string;

  constructor(id?: number, spotId?: string, spotName?: Language) {
    this.id = id;
    this.spotId = spotId;
    this.spotName = spotName;
  }
}

// Interface representing language structure
interface Language {
  ja: string; // Japanese language representation
  en: string; // English language representation
}
